import React, { useRef, useMemo } from 'react';
import ReactDOM from 'react-dom';
import videojs from '@mux/videojs-kit/dist/index.vhs';
import moment from 'moment';

const transformChapters = chapters => {
  try {
    return chapters.map((chapter, index) => ({
      start: chapter.timecode,
      end: chapters[index + 1] ? chapters[index + 1].timecode - 1 : null,
      title: chapter.title,
    }));
  } catch (error) {
    return [];
  }
};

const addIntroChapter = chapters => [{ start: 0, end: chapters[0].start - 1, title: 'Introduction' }, ...chapters];

const getChapterTitle = (transformedChapters, seconds) => {
  try {
    const chapter = transformedChapters.find(c => {
      if (c.end === null) return seconds >= c.start;
      return seconds >= c.start && seconds <= c.end;
    });
    return chapter?.title;
  } catch (error) {
    return null;
  }
};

const useChapters = (player, chapters) => {
  const chapterTitleRef = useRef(null);
  const transformedChapters = useMemo(() => (chapters?.length ? addIntroChapter(transformChapters(chapters)) : []), [chapters]);

  if (!player || !transformedChapters?.length) return;

  const progressControl = player.$('.vjs-progress-control');

  const chapterTitleContent = (title, time) => (
    <>
      <span className="chapter-title">{title}</span>
      <span className="chapter-time">{time}</span>
    </>
  );

  const OnProgressBarMouseMove = event => {
    const position = videojs.dom.getPointerPosition(progressControl, event).x;
    const duration = player.duration();
    const seconds = Math.round(position * duration);
    const time = moment.utc(seconds * 1000).format('mm:ss');
    const title = getChapterTitle(transformedChapters, seconds);
    ReactDOM.render(
      chapterTitleContent(title, time),
      chapterTitleRef.current
    );
  };

  const buildChapterElements = () => {
    const timeLinePreviewBox = player.$('.vjs-vtt-thumbnail-display');
    const duration = player.duration();
    chapterTitleRef.current = Object.assign(document.createElement('div'), {
      className: 'chapter-title-box',
    });
    timeLinePreviewBox.appendChild(chapterTitleRef.current);

    if (progressControl) {
      chapters.forEach(chapter => {
        if (chapter.timecode < duration) {
          const markerEl = document.createElement('div');
          markerEl.className = 'chapter-marker';
          markerEl.style.left = `${(chapter.timecode / duration) * 100}%`;
          progressControl.appendChild(markerEl);
        }
      });
    }
  };

  player.on('loadedmetadata', () => {
    buildChapterElements();
  });

  player.on('ready', () => {
    const mouseTimeDisplay = player.getChild('ControlBar').getChild('ProgressControl').getChild('SeekBar').getChild('MouseTimeDisplay');
    mouseTimeDisplay.hide();
    progressControl.addEventListener('mousemove', OnProgressBarMouseMove);
  });
};

export default useChapters;